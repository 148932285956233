import "bootstrap"
import "@fortawesome/fontawesome-free/js/all"
import "./jQueryStimulus"

import Autosize from "./Autosize"
import CheckboxToggler from "./CheckboxToggler"
import ColorPicker from "./ColorPicker"
import DateTimePicker from "./DateTimePicker"
import Slider from "./Slider"
import Dialog from "./Dialog"
import DirectUpload from "./DirectUpload"
import FileInput from "./FileInput"
import FormErrors from "./FormErrors"
import ImageElement from "./ImageElement"
import InputMask from "./InputMask"
import LoadingButton from "./LoadingButton"
import Notification from "./Notification"
import PopOver from "./PopOver"
import RadioToggler from "./RadioToggler"
import RemoteForm from "./RemoteForm"
import ScrollBottom from "./ScrollBottom"
import SelectBox from "./SelectBox"
import Spinner from "./Spinner"
import t from "./utils/t"
import TimeZone from "./TimeZone"
import ToggleDataOn from "./ToggleDataOn"
import Toggler from "./Toggler"
import AttrToggler from "./AttrToggler"
import ToolTip from "./ToolTip"
import Ujs from "./Ujs"
import Validations from "./Validations"
import WebSocket from "./WebSocket"

const defaultSettings = {
  ajaxStatus: {
    0: { message: "Connection lost", description: "Couldn't get a response from the server.<br/> Please check your internet connection." },
    302: false, // prevents the error to be shown
    422: false, // prevents the error to be shown
    500: { description: "Ups, something went wrong.<br/> We have been notified, please try again later." },
  }
}
const settings = { ...defaultSettings }

const Common = {
  config(customSettings = {}) {
    $.extend(true, settings, customSettings)

    Ujs.config(settings.Ujs)
    Slider.config(settings.Slider)
    Dialog.config(settings.Dialog)
    PopOver.config(settings.PopOver)
    ToolTip.config(settings.ToolTip)
    Spinner.config(settings.Spinner)
    Autosize.config(settings.Autosize)
    TimeZone.config(settings.TimeZone)
    WebSocket.config(settings.WebSocket)
    SelectBox.config(settings.SelectBox)
    InputMask.config(settings.InputMask)
    FileInput.config(settings.FileInput)
    FormErrors.config(settings.FormErrors)
    ImageElement.config(settings.ImageElement)
    ColorPicker.config(settings.ColorPicker)
    Notification.config(settings.Notification)
    DirectUpload.config(settings.DirectUpload)
    ScrollBottom.config(settings.ScrollBottom)
    LoadingButton.config(settings.LoadingButton)
    DateTimePicker.config(settings.DateTimePicker)
  },

  Autosize,
  CheckboxToggler,
  ColorPicker,
  DateTimePicker,
  Slider,
  Dialog,
  FileInput,
  FormErrors,
  ImageElement,
  InputMask,
  LoadingButton,
  Notification,
  PopOver,
  RadioToggler,
  RemoteForm,
  SelectBox,
  Spinner,
  t,
  TimeZone,
  ToggleDataOn,
  Toggler,
  AttrToggler,
  ToolTip,
  Validations,
  WebSocket,

  getElement(elementOrSelector) {
    if (elementOrSelector && elementOrSelector.constructor === String) {
      return document.querySelector(elementOrSelector);
    }

    return elementOrSelector;
  },

  getDataset(element, defaultData = {}) {
    const data = {}

    function castValue(value) { // function from jQuery#data
      if (value === 'false') return false
      if (value === 'true') return true
      if (value === 'null') return null

      // Only convert to a number if it doesn't change the string
      if (value === +value + '') return +value;

      //Replaced rbrace with regex value.
      if (/^(?:\{[\w\W]*\}|\[[\w\W]*\])$/.test(value)) {
        return JSON.parse(value);
      }

      return value
    }

    Object.keys(element.dataset).forEach(key => {
      data[key] = castValue(element.dataset[key])
    })

    return { ...defaultData, ...data };
  },

  replaceContent(targetSelector, htmlData, replacementSelector) {
    const $element = $(targetSelector)
    const id = $element.attr("id")

    if (!replacementSelector && id) { replacementSelector = `#${id}` }

    if (replacementSelector) {
      const $content = $(`<div>${htmlData}</div>`)
      const $replacement = $content.find(replacementSelector)

      if ($replacement.length) {
        return $element.replaceWith($replacement)
      }
    }

    if ($element.data("replace") === "itself") {
      $element.replaceWith(htmlData)
    } else {
      $element.html(htmlData)
    }
  },

  handleAjaxError(xhr, errorCallback) {
    if (xhr.status >= 200 && xhr.status <= 299) { return true }

    let ajaxStatus = settings.ajaxStatus[xhr.status]

    if (ajaxStatus === false) { return true }

    if (!ajaxStatus) { ajaxStatus = {} }

    errorCallback(ajaxStatus.message || xhr.statusText, ajaxStatus.description)
  },

  loadContentTo(selector, url) {
    const $element = $(selector);

    $.ajax({
      url: url,
      data: { layout: false },
      success(data, _textStatus, _jqXHR) {
        Common.replaceContent($element, data)
      },
      complete(jqXHR, _textStatus) {
        Common.handleAjaxError(jqXHR, Common.Notification.warning)

        $element.attr("data-busy", "false")
      }
    })

    $element.attr("data-busy", "true")

    return false;
  }
}

export default Common
