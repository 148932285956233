const RadioToggler = {
  load(selector) {
    const $label = $(selector)

    if ($label.data("radioToggler")) { return false }

    const $radioInput = $label.find("input[type='radio']")

    if (!$radioInput.length) { return false }

    $radioInput.data("$label", $label)

    $label.on("click", checkRadioInput)
    $radioInput.on("change", paintLabel)

    $label.data("radioToggler", true)
  },
}

export default RadioToggler

/* ********************** PRIVATE ********************** */

function checkRadioInput(event) {
  const $label = $(this)
  const $form = $label.closest("form")
  const $radioInputs = $form.find(`input[data-input-name='${$label.data("inputName")}']`)

  $radioInputs.each(function () {
    const $this = $(this)

    if ($this.data("$label").get(0) == $label.get(0)) {
      $this.attr("checked", "checked")
    } else {
      $this.removeAttr("checked")
    }

    $this.triggerStimulus("change")
  })

  event.preventDefault()

  return false
}

function paintLabel() {
  const $radioInput = $(this)
  const $label = $radioInput.data("$label")

  const checkedClass = $label.data("checkedClass")
  const uncheckedClass = $label.data("uncheckedClass")

  if ($radioInput.attr("checked") === "checked") {
    $label.addClass(checkedClass).removeClass(uncheckedClass)
  } else {
    $label.addClass(uncheckedClass).removeClass(checkedClass)
  }
}
