import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const $element = $(this.element)
    const url = $element.data("src")

    Common.loadContentTo($element, url);
  }
}
