// https://select2.org/configuration/options-api
import "select2"

import loadCssFile from "./utils/loadCssFile"

const FLAG_ICONS_PATH = "/vendor/flag-icon-css/css/flag-icon.min.css"

const defaultSettings = {
  theme: 'bootstrap4',
  allowClear: true,
  placeholder: "Select",
  templateResult: templateResult,
  templateSelection: templateSelection,
}
const settings = { ...defaultSettings }

const SelectBox = {
  config(customSettings) {
    $.extend(true, settings, customSettings)
  },

  load(selector, customOptions) {
    const $element = $(selector)

    if ($element.data("selectbox")) { return false }

    const options = buildOptions($.extend({}, $element.data(), customOptions))
    const $modal = $element.parents(".modal")
    const mode = options.mode || ""

    // https://select2.org/troubleshooting/common-problems
    if ($modal.length) { options.dropdownParent = $modal }

    $element.select2(options)

    if (mode.includes("remote-data")) {
      loadRemoteData($element, options)
    }

    if (mode.includes("load-flag-icons")) {
      loadCssFile('flagIcons', FLAG_ICONS_PATH)
    }

    if (mode.includes("copy-selection-to-dropdown")) {
      copySelectionToDropdown($element, options)
    }

    $element.on('select2:select', function () {
      const event = new Event('change', { bubbles: true }) // fire a native event

      this.dispatchEvent(event)
    });

    $element.on('select2:clearing', function () {
      const event = new Event('change', { bubbles: true }) // fire a native event

      this.dispatchEvent(event)
    });

    $element.data("selectbox", true)
  },

  open(selector) {
    $(selector).select2('open')

    return false
  },
}

export default SelectBox

/* ********************** PRIVATE ********************** */

function buildOptions(options) {
  const newOptions = $.extend({}, settings, options)
  const extraOptions = {
    "tags": { multiple: true, tags: true, tokenSeparators: [","] },
    "multiple": { multiple: true },
    "no-search": { minimumResultsForSearch: Infinity },
    "no-clear": { allowClear: false },
    "phone-flags": {
      mode: `${newOptions.mode} remote-data load-flag-icons copy-selection-to-dropdown`,
      url: "/api/reference_data/country_options",
      allowClear: false,
      templateResult: templateResultPhoneFlags,
      templateSelection: templateSelectionPhoneFlags,
    },
    "country-list": {
      mode: `${newOptions.mode} remote-data load-flag-icons`,
      url: "/api/reference_data/country_options",
      templateResult: templateResultCountryList,
      templateSelection: templateSelectionCountryList,
    },
  }

  for (const property in extraOptions) {
    if ((options.mode || "").includes(property)) {
      $.extend(newOptions, extraOptions[property])
    }
  }

  return newOptions
}

function copySelectionToDropdown($element, options) {
  $element.on("select2:select", function (event) {
    let text = event.params.data

    if (options.templateSelection) {
      text = options.templateSelection(event.params.data)
    }

    $(event.currentTarget)
      .parent()
      .find("[data-id='dropdown-button']")
      .html(text)
  })
}

function loadRemoteData($element, options) {
  let dataLoaded = false

  $element.on("select2:clearing", function (e) {
    $element.find("option[selected]").removeAttr("selected")
  })

  $element.select2($.extend({}, options, {
    language: { noResults: () => "Searching..." }
  }))

  $element.on("select2:opening", function (e) {
    if (dataLoaded) { return this }

    $.ajax({
      type: "GET",
      url: options.url,
      dataType: "json",
      success: function(response) {
        const data = response.data || response;

        if (options.allowClear) {
          data.unshift({ id: "", text: "" })
        }

        /* We need to clear existing options, to prevent select2 from
           selecting the previous select option again, after data
           comes from the server, when the user had alread unselected it.*/
        if (!$element.find("option[selected]").length) {
          $element.empty().trigger("change")
        }
        $element.select2($.extend({}, options, { data: data }))

        dataLoaded = true

        $element.select2("open")
      }
    })
  })
}

function templateSelection(data) {
  if (data.short_name) {
    const code = data.code ? " (" + data.code + ")" : "";

    return data.short_name + code
  } else {
    return $("<span>" + data.text + "</span>")
  }
}

function templateResult(data) {
  if (data.text) {  }

  if (data.name) {
    const code = data.code ? " (" + data.code + ")" : "";

    data.text = data.name + code

    return data.text
  } else {
    return $("<span>" + data.text + "</span>")
  }
}

function templateSelectionPhoneFlags(data, container) {
  const text = "<span class='d-none d-sm-inline'>(+" + (data.title || data.code) + ")</span>"

  return $.parseHTML("<span class='flag-icon flag-icon-" + data.id.toLowerCase() + " me-1'></span> " + text)
}

function templateResultPhoneFlags(data) {
  if (!data.text) {
    data.text = data.name + " (+" + data.code + ")"
  }

  if (data.id) {
    return $.parseHTML("<span class='flag-icon flag-icon-" + data.id.toLowerCase() + " me-1'></span> " + data.text)
  } else {
    return data.text
  }
}

function templateSelectionCountryList(data, container) {
  const text = data.title || data.short_name

  if (text) {
    return $.parseHTML("<span class='flag-icon flag-icon-" + data.id.toLowerCase() + " me-1'></span> " + text)
  } else {
    return data.text
  }
}

function templateResultCountryList(data) {
  const text = data.title || data.name

  if (text) {
    data.text = text

    return $.parseHTML("<span class='flag-icon flag-icon-" + data.id.toLowerCase() + " me-1'></span> " + data.text)
  } else {
    return data.text
  }
}
