const defaultSettings = {
  offset: 80,
}
const settings = { ...defaultSettings }

var ScrollBottom = {
  config(customSettings) {
    $.extend(true, settings, customSettings)

    const $window = $(window)
    const $document = $(document)

    function scrollBottomCallback() {
      if (($document.height() - $window.scrollTop()) <= ($window.outerHeight() + settings.offset)) {
        if (!settings.reachedBottom) {
          $("[data-click-when='window-bottom']").click()

          settings.reachedBottom = true;
        }
      } else {
        settings.reachedBottom = false;
      }
    }

    $window.off("scroll.bottom")
    $window.on("scroll.bottom", scrollBottomCallback)
  },
}

export default ScrollBottom
