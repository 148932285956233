import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["container", "template", "attachments", "input"]
  static values = { multiple: Boolean, required: Boolean }

  connect() {
    const $input = $(this.inputTarget)
    const $container = $(this.containerTarget)
    const templateHtml = $(this.templateTarget).html()

    if (this.multipleValue) {
      $input.on("change", function() {
        Common.FileInput.breakIntoSeparateFiles($input.get(0), $container, templateHtml)
      })
    } else {
      $input.on("change", function() {
        Common.FileInput.previewFileFromInput(
          $input.get(0),
          $container,
          templateHtml,
          this.requiredValue
        )
      })
    }

    this.attachmentsTargets.forEach(function (attachment) {
      Common.FileInput.appendPreviewTemplate(
        $container,
        templateHtml,
        $(attachment).data()
      )
    })
  }
}
