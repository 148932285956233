const defaultSettings = {
  errors: {},
  inputInvalidClass: "is-invalid",
  messageInvalidClass: "invalid-feedback",
  formGroupInvalidClass: "form-group-invalid",
}
const settings = { ...defaultSettings }

const FormErrors = {
  config(customSettings) {
    $.extend(true, settings, customSettings)
  },

  formHasErrors(selector) {
    const $element = $(selector)

    if ($element.find("." + settings.formGroupInvalidClass).length) { return true }
    if ($element.find("." + settings.inputInvalidClass).length) { return true }

    return false
  },

  showError(selector, errorMessage, overwrite = false) {
    const $element = $(selector)
    const $parent = $element.parent()
    const message = settings.errors[errorMessage] || errorMessage

    if (overwrite) { FormErrors.removeError(selector) }

    if (!$parent.hasClass(settings.formGroupInvalidClass)) {
      $parent.addClass(settings.formGroupInvalidClass)
      $element.addClass(settings.inputInvalidClass)
      $parent.append(`<div class="${settings.messageInvalidClass}">${message}</div>`)
    }

    FormErrors.disableForm(selector)
  },

  removeError(selector) {
    const $element = $(selector)
    const $parent = $element.parent()

    $parent.removeClass(settings.formGroupInvalidClass)
    $element.removeClass(settings.inputInvalidClass)
    $parent.find(`.${settings.messageInvalidClass}`).remove()

    FormErrors.enableForm(selector)
  },

  removeAllErrors(selector) {
    const $element = $(selector)
    const $form = $element.is("form") ? $element : $element.closest("form")

    $form.find(`.${settings.formGroupInvalidClass}`).removeClass(settings.formGroupInvalidClass)
    $form.find(`.${settings.inputInvalidClass}`).removeClass(settings.inputInvalidClass)
    $form.find(`.${settings.messageInvalidClass}`).remove()

    FormErrors.enableForm(selector)
  },

  enableForm(selector, force) {
    const $element = $(selector)
    const $form = $element.is("form") ? $element : $element.closest("form")

    if (force !== true && $(`.${settings.messageInvalidClass}`).length) { return false }

    $form.removeAttr("disabled")

    $form.find(`[type="submit"]`).removeAttr("disabled")

    $(`[form="${$form.attr("id")}"]`).removeAttr("disabled")
  },

  disableForm(selector) {
    const $element = $(selector)
    const $form = $element.is("form") ? $element : $element.closest("form")

    $form.attr("disabled", "disabled")

    $form.find(`[type="submit"]`).attr("disabled", "disabled")

    $(`[form="${$form.attr("id")}"]`).attr("disabled", "disabled")
  },
}

export default FormErrors
