import Common from "Common"
import i18n from "./i18n.js.erb"
import StripeWrapper from "./StripeWrapper"

import "./controllers"

const settings = {
  locale: "en"
}

const Application = {
  config(customSettings) {
    $.extend(true, settings, customSettings)
    $.extend(true, settings, i18n[settings.locale])

    Common.config(settings)

    StripeWrapper.config(settings.StripeWrapper)
  },

  StripeWrapper,
}

export { Common }

export default Application

/* ********************** PRIVATE ********************** */

document.addEventListener("turbolinks:before-cache", function() {
  Common.Notification.dismissAll()

  const noCacheElements = document.querySelectorAll("[data-turbolinks='no-cache']")

  if (noCacheElements.length) {
    noCacheElements.forEach(function(noCacheElement) {
      noCacheElement.remove()
    })
  }
})
