// https://getbootstrap.com/docs/5.0/components/spinners/#buttons

const defaultSettings = {
  size: "small",
  type: "border",
  color: "primary",
  style: "expand-right",
  timeout: 10000,
}
const settings = { ...defaultSettings }

const LoadingButton = {
  config(customSettings) {
    $.extend(true, settings, customSettings)
  },

  load(selector) {
    const $element = $(selector)

    if ($element.data("loading-button")) { return false }

    const options = { ...settings, ...$element.data() }

    $element.html(spinnerContainerTemplate($element.html(), options))

    $element.data("loading-button", true)

    if ($element.data("remote") === true) {
      $element.on("ajax:before", () => LoadingButton.start(selector))
      $element.on("ajax:complete", () => LoadingButton.stop(selector))
      $element.on("ajax:stopped", () => LoadingButton.stop(selector))
    } else {
      $element.on("click", () => {
        setTimeout(function () {
          LoadingButton.start(selector)
        }, 1)

        if (Number(options.timeout) > 0) {
          let timeout = $element.data("loadingButtonTimeout")
          if (timeout) { clearTimeout(timeout) }

          timeout = setTimeout(function () {
            LoadingButton.stop(selector)
          }, options.timeout)

          $element.data("loadingButtonTimeout", timeout)
        }

        return true
      })
    }
  },

  start(selector) {
    const $element = $(selector)

    const options = { ...settings, ...$element.data() }

    $element.addClass("disabled").attr("disabled", "disabled")
    if (options.style.includes("zoom")) { $element.find(".content").addClass("invisible") }
    $element.find(".spinnerContainer").html(spinnerTemplate(options))
  },

  stop(selector) {
    const $element = $(selector)

    $element.removeClass("disabled").removeAttr("disabled")
    $element.find(".content").removeClass("invisible")
    $element.find(".spinnerContainer").html("")
  },
}

export default LoadingButton

/* ********************** PRIVATE ********************** */

function spinnerTemplate(options) {
  const size = options.size === "small" ? "spinner-border-sm" : ""
  let margin = ""

  if (options.style.includes("left")) { margin = "me-2" }
  if (options.style.includes("right")) { margin = "ms-2" }

  return `<span class="${margin}">
  <span class="spinner-${options.type} ${size}" role="status" aria-hidden="true"></span>
</span>`
}

function spinnerContainerTemplate(content, options) {
  let spinnerCSS = ""

  if (options.style.includes("left")) { spinnerCSS = "float-start" }
  if (options.style.includes("zoom")) { spinnerCSS = "centered-overlay" }

  return `<span class="wrapper position-relative">
  <span class="content">${content}</span>
  <span class="spinnerContainer ${spinnerCSS}"></span>
</span>`
}
