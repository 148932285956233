import { Controller } from 'stimulus'

export default class extends Controller {
  validateURL(event) {
    const element = event.target;

    Common.Validations.validateURL(element);
  }

  validatePresence(event) {
    const element = event.target;

    Common.Validations.validatePresence(element);
  }
}
