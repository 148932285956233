const RemoteForm = {
  load(selector, callback) {
    const $element = $(selector)
    const formId = $element.attr("id")
    const targetSelector = $element.data("target") || `#${formId}`

    $element.append(`<input type="hidden" name="layout" value="false" />`)

    $element.on("ajax:complete", function(event) {
      $(`[form="${formId}"]`).each(function () {
        Common.LoadingButton.stop(this)
      })

      RemoteForm.replaceContent(targetSelector, event, targetSelector)

      if (callback) { callback(targetSelector, event) }
    })
  },

  replaceContent(targetSelector, event, replacementSelector) {
    const [xhr, _status] = event.detail

    // Keep the original behaviour and don't replace anything in case of a redirect
    if (xhr.getResponseHeader("x-xhr-redirect")) {
      return false
    }

    if (xhr.status === 200 || xhr.status === 422) {
      Common.replaceContent(targetSelector, xhr.responseText, replacementSelector)
    }
  }
}

export default RemoteForm
