// https://clipboardjs.com/
import ClipboardJS from 'clipboard';

import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    new ClipboardJS(this.element);
  }
}
