(function ($) {
  $.fn.triggerStimulus = function(eventName) {
    const jQueryEl = this

    jQueryEl.trigger(eventName)
    jQueryEl.each(function () {
      const event = document.createEvent("Event")
      event.initEvent(eventName, true, true)
      this.dispatchEvent(event)
    })

    return jQueryEl
  }

  $.fn.changeValue = function(value) {
    const jQueryEl = this

    jQueryEl.val(value)
    jQueryEl.triggerStimulus("input")

    return jQueryEl
  }
})(jQuery);


export default {}
