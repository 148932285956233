const ATTR_NAME_SEPARATORS = {
  'class': ' ',
};
const DEFAULT_SEPARATOR = ',';

const AttrToggler = {
  load(selector) {
    const $toggler = $(selector)

    if (!$toggler.data('attrTogglerLoaded')) {
      $toggler.one('change', function () { toggleTargetsFor($toggler); });

      $toggler.data('attrTogglerLoaded', true)
    }

    toggleTargetsFor($toggler);
  }
};

export default AttrToggler;

/* ********************** PRIVATE ********************** */

function toggleTargetsFor($toggler) {
  const config = $toggler.data('attrTogglerConfig');
  const rules = Array.isArray(config) ? config : [config];

  rules.forEach(ruleConfig => toggleTarget($toggler.val(), ruleConfig));
}

function toggleTarget(togglerValue, config) {
  const { when, when_not: whenNot, attr_name: attrName, attr_value: attrValue, target: targetSelector } = config;

  if (when) {
    const whenValues = Array.isArray(when) ? when : [when];

    if (whenValues.includes(togglerValue)) {
      $(targetSelector).each(function () { toggleAttr('add', this, attrName, attrValue) });
    } else {
      $(targetSelector).each(function () { toggleAttr('remove', this, attrName, attrValue) });
    }
  }

  if (whenNot) {
    const whenNotValues = Array.isArray(whenNot) ? whenNot : [whenNot];

    if (!whenNotValues.includes(togglerValue)) {
      $(targetSelector).each(function () { toggleAttr('add', this, attrName, attrValue) });
    } else {
      $(targetSelector).each(function () { toggleAttr('remove', this, attrName, attrValue) });
    }
  }
}

function toggleAttr(method, target, attrName, attrValue) {
  if (attrValue) {
    return toggleAttrValue(method, target, attrName, attrValue);
  }

  const $target = $(target);
  const targetAttrValue = $target.attr(attrName);

  if (method === 'remove' && targetAttrValue) {
    $target.removeAttr(attrName);
  }

  if (method === 'add' && !targetAttrValue) {
    $target.attr(attrName, attrName);
  }
}

function toggleAttrValue(method, target, attrName, attrValue) {
  const $target = $(target);
  const separator = ATTR_NAME_SEPARATORS[attrName] || DEFAULT_SEPARATOR;
  const targetAttrValue = $target.attr(attrName);
  const newAttrValues = Array.isArray(targetAttrValue) ? [...targetAttrValue] : (targetAttrValue || '').split(separator);

  if (method === 'remove' && newAttrValues.includes(attrValue)) {
    newAttrValues.splice(newAttrValues.indexOf(attrValue), 1);
  }

  if (method === 'add' && !newAttrValues.includes(attrValue)) {
    newAttrValues.push(attrValue);
  }

  $target.attr(attrName, Array.isArray(targetAttrValue) ? newAttrValues : newAttrValues.join(separator));
}
