const Toggler = {
  load(selector) {
    const $element = $(selector)
    const id = $element.attr('id');

    if ($element.data('toggler')) { return false }

    $element.on('change', function () {
      toggleTargets($element, 'toggled-by');
      if (id) toggleTargets($element, `toggled-by-${id}`);
    });

    toggleTargets($element, 'toggled-by');
    if (id) toggleTargets($element, `toggled-by-${id}`);

    $element.data('toggler', true)
  },
}

export default Toggler

/* ********************** PRIVATE ********************** */

function toggleTargets($element, dataAttrName) {
  $(`[data-${dataAttrName}]`).each(function () {
    const $this = $(this);
    const values = $this.data(dataAttrName).split(',');

    if (values.includes($element.val())) {
      $this.show();
    } else {
      $this.hide();
    }
  });
}
