const defaultSettings = {
  currentOffset: 0
}
const settings = { ...defaultSettings }

const TimeZone = {
  config(customSettings) {
    $.extend(true, settings, customSettings)

    // getTimezoneOffset returns the difference of UTC minus local time, in minutes. We want local time minus UTC difference.
    const browserTimeZoneOffset = new Date().getTimezoneOffset() * -1

    if (settings.currentOffset !== browserTimeZoneOffset) {
      $.ajax({
        url: '/time_zone_offset',
        method: 'PUT',
        data: { time_zone_offset: browserTimeZoneOffset },
      })
    }
  }
}

export default TimeZone
