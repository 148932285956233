import { Controller } from "stimulus"

let lastTimestamp = null

export default class extends Controller {
  static values = { timestamp: Number, data: Object }

  connect() {
    if (this.timestampValue && lastTimestamp && this.timestampValue <= lastTimestamp) {
      return false
    }

    lastTimestamp = this.timestampValue

    if (this.dataValue) {
      for (const type in this.dataValue) {
        const message = this.dataValue[type]

        if (message && message !== "") {
          Common.Notification[type](message)
        }
      }
    }
  }
}
