import { Controller } from "stimulus"

function lazyLoadDialog(event) {
  const $element = $(this)
  const { id, title, ...data } = $element.data()

  if (event.metaKey || event.ctrlKey) {
    return true
  }

  Common.Dialog.lazyLoad(this.href, id, title || $element.data('bsOriginalTitle'), data)

  return false
}

export default class extends Controller {
  connect() {
    $(this.element).on("click", lazyLoadDialog)
  }
}
