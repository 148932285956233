import autosize from "autosize";

const defaultSettings = {
}
const settings = { ...defaultSettings }

const Autosize = {
  config(customSettings) {
    $.extend(true, settings, customSettings)
  },

  load(selector) {
    $(selector).each(() => {
      autosize(this);
    })
  }
}

export default Autosize
