// https://getbootstrap.com/docs/5.0/components/spinners/

const defaultSettings = {
  spinnerClass: "centered-overlay",
  type: "border",
  size: "",
  color: "primary",
}
const settings = { ...defaultSettings }

const Spinner = {
  config(customSettings) {
    $.extend(true, settings, customSettings)
  },

  load(selector, customSettings) {
    const $element = $(selector)

    if ($element.data("preventLoad") === true) { return true }
    if ($element.find("[data-id='spinner']").length > 0) { return false }

    const options = { ...settings, ...$element.data(), ...customSettings }

    $element.append(spinnerTemplate(options))
  },

  stop(selector, { preventLoadAgain = false }) {
    const $element = $(selector)

    $element.find("[data-id='spinner']").remove()

    if (preventLoadAgain) { $element.data("preventLoad", true) }
  },

  stopClosestSpinner(selector, { preventLoadAgain = false }) {
    const $closestSpinner = $(selector).closest("[data-controller='spinner']")

    if ($closestSpinner.length) {
      Spinner.stop($closestSpinner.get(0), { preventLoadAgain })
    }
  }
}

export default Spinner

/* ********************** PRIVATE ********************** */

function spinnerTemplate(options) {
  return `<div data-id="spinner" class="${options.spinnerClass}">
  <div class="${spinnerClassNames(options)}" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>`
}

function spinnerClassNames(options) {
  const sizeCss = options.size === "small" ? `spinner-${options.type}-sm` : ""

  return `spinner-${options.type} text-${options.color} ${sizeCss}`
}
