// https://github.com/Simonwep/pickr
import Pickr from "@simonwep/pickr";

const defaultSettings = {
  theme: "nano",

  defaultRepresentation: "HEX",

  swatches: [
    "#6161FF",
    "#FF8761",
    "#77FF61",
    "#FF6161",
    "#FF7777",
    "#77DEFF",
    "#9777FF",
    "#0E0E10",
    "#EAEAEC",
    "#F7F7F7",
    "#FFFFFF",
  ],

  components: {
    // Main components
    preview: true,
    opacity: false,
    hue: true,

    // Input / output Options
    interaction: {
      // hex: true,
      // rgba: true,
      input: true,
      clear: false,
      save: true
    }
  }
}
const settings = { ...defaultSettings }

const ColorPicker = {
  config(customSettings) {
    $.extend(true, settings, customSettings)
  },

  load(selector, customSettings) {
    const $element = $(selector)
    const inputName = $element.data("inputName")
    const $input = $(`[type='text']${inputName}`)

    if ($element.data("colorpicker")) { return false }

    const options = {
      ...settings,
      ...$element.data(),
      ...customSettings,
      el: selector,
    }

    const colorpicker = Pickr.create(options)

    colorpicker.on('save', (color, instance) => {
      $input.changeValue(color.toHEXA().toString(0))
      colorpicker.hide()
    })

    $element.data("colorpicker", colorpicker)
  }
}

export default ColorPicker
