import createConsumer from "channels/createConsumer"

const settings = {
  token: ''
}

let consumer = null;

const WebSocket = {
  config(customSettings) {
    $.extend(settings, customSettings)

    consumer = createConsumer(`/cable?token=${settings.token}`);
  },

  createSubscription(...args) {
    return consumer.subscriptions.create(...args);
  }
}

export default WebSocket;
