// https://getbootstrap.com/docs/5.0/components/popovers
import { Popover } from "bootstrap"

const defaultSettings = {
  animation: true,
  delay: 0,
}
const settings = { ...defaultSettings }

export default {
  config(customSettings) {
    $.extend(true, settings, customSettings)
  },

  load(selector, customSettings) {
    const $element = $(selector)

    if ($element.data("popover")) { return false }

    const options = { ...settings, ...$element.data(), ...customSettings }

    const popover = new Popover(selector, options)

    $element.data("popover", popover)

    if (options.trigger === 'manual') {
      popover.show()
    }
  },
}
