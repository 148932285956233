// https://flatpickr.js.org/
import "flatpickr"

// Date Formats: https://flatpickr.js.org/formatting/
const defaultSettings = {
  dateFormat: "Y-m-d",
  enableTime: false,
  noCalendar: false
}
const settings = { ...defaultSettings }

const DateTimePicker = {
  config(customSettings) {
    $.extend(true, settings, customSettings)
  },

  load(selector, customSettings) {
    const $element = $(selector)

    if ($element.data("datepicker")) { return false }

    const options = { ...settings, ...$element.data(), ...customSettings }

    if (options.mode === 'time') {
      options.enableTime = true
      options.noCalendar = true
    } else if (options.mode === 'datetime') {
      options.enableTime = true
      options.noCalendar = false
    }

    const datepicker = flatpickr(selector, options)

    $element.data("datepicker", datepicker)
  }
}

export default DateTimePicker
