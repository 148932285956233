// https://www.jacklmoore.com/autosize/
import autosize from "autosize"

import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    autosize(this.element)
  }
}
