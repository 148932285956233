// https://stripe.com/docs/billing/subscriptions/elements

let stripe = null
const defaultSettings = {
  options: {},
  cardSelector: "#stripe-card",
  submitButtonSelector: "#stripe-pay-button",
  errorsSelector: "#stripe-errors",
  publishableKey: null,
}
const settings = { ...defaultSettings }

const StripeWrapper = {
  config(customSettings) {
    $.extend(true, settings, customSettings)

    if (window.Stripe) {
      stripe = Stripe(settings.publishableKey)
    }
  },

  load(selector) {
    const $form = $(selector)

    if (!stripe) { return false }
    if ($form.data("stripeCard")) { return false }

    const stripeCard = buildStripeCard($form, settings.options)

    $form.data("stripeCard", stripeCard)
  },

  makePayment($form, clientSecret) {
    const stripeCard = $form.data("stripeCard")
    const $submitButton = $form.find(settings.submitButtonSelector)

    if (!stripe) { return false }

    stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: stripeCard,
        billing_details: {
        },
      }
    }).then((result) => {
      if (result.error) {
        Common.LoadingButton.stop($submitButton)

        displayError($form, result.error.message)
      } else {
        document.getElementById("restore_purchase").click()
      }
    })

    setTimeout(function () {
      Common.LoadingButton.start($submitButton)
    }, 50)
  }
}

export default StripeWrapper

/* ********************** PRIVATE ********************** */

function buildStripeCard($form, options) {
  const $card = $form.find(settings.cardSelector)
  const elements = stripe.elements()
  const stripeCard = elements.create('card', options)

  stripeCard.mount($card.get(0))

  stripeCard.on('change', (event) => {
    const message = event.error ? event.error.message : null

    return displayError($form, message)
  })

  return stripeCard
}

function displayError($form, message) {
  const $errors = $form.find(settings.errorsSelector)
  const $submitButton = $form.find(settings.submitButtonSelector)

  if (message) {
    $errors.html(message)
    $submitButton.addClass("disabled").attr("disabled", "disabled")
  } else {
    $errors.html("")
    $submitButton.removeClass("disabled").removeAttr("disabled")
  }
}
