// https://getbootstrap.com/docs/5.0/components/tooltips
import { Tooltip } from "bootstrap"

const defaultSettings = {
  animation: true,
  delay: 200,
}
const settings = { ...defaultSettings }

export default {
  config(customSettings) {
    $.extend(true, settings, customSettings)
  },

  load(selector, customSettings) {
    const $element = $(selector)

    if ($element.data("tooltip")) { return false }

    const options = { ...settings, ...$element.data(), ...customSettings }

    const tooltip = new Tooltip(selector, options)

    $element.data("tooltip", tooltip)
  },

  dispose(selector) {
    const $element = $(selector)

    const tooltip = $element.data("tooltip")

    if (tooltip) {
      tooltip.dispose()

      $element.data("tooltip", null)
    }
  }
}
