// https://igorescobar.github.io/jQuery-Mask-Plugin/docs.html
import "jquery-mask-plugin"

const defaultSettings = {
  translation: {
    '0': { pattern: /\d/ },
    '9': { pattern: /\d/, optional: true },
    '#': { pattern: /\d/, recursive: true },
    'A': { pattern: /[a-zA-Z0-9]/ },
    'S': { pattern: /[a-zA-Z]/ },
    'P': { pattern: /[1-9]/ },
  }
}
const settings = { ...defaultSettings }

const InputMask = {
  config(customSettings) {
    $.extend(true, settings, customSettings)
  },

  load(selector, customSettings) {
    const $element = $(selector)

    let { mask, ...options } = { ...settings, ...$element.data(), ...customSettings }

    if (typeof(mask) === 'object') { mask = mask.mask }

    $element.mask(mask, options)
  }
}

export default InputMask
