// https://getbootstrap.com/docs/5.0/components/modal/
import { Modal } from "bootstrap"

const defaultSettings = {
  id: "all-purpose-modal",
  zIndex: 1050,
  lazyLoadErrorMessage: "Failed to load content.",
  alertOkButton: "Ok",
  confirmOkButton: "Yes",
  confirmCancelButton: "No",
  confirmMessage: 'Are you sure?',
  cancelButton: "Close",
  template: allPurposeModalTemplate,
  keyboard: true,
  backdrop: true
}
const settings = { ...defaultSettings }

const Dialog = {
  config(customSettings) {
    $.extend(true, settings, customSettings)
  },

  /*
    OPTIONS
      id != null # will dictate which html element to use for the modal
      scrollable == true # will make the modal's content scrollable
      centered == true # will vertically center the modal
      size == 'small' # will show a small modal
      size == 'large' # will show a large modal
      template != undefined # will overwrite the Modal's template
      onShow != undefined # will add that callback to 'show.bs.modal' event
      onShown != undefined # will add that callback to 'shown.bs.modal' event
      onHide != undefined # will add that callback to 'hide.bs.modal' event
      onHidden != undefined # will add that callback to 'hidden.bs.modal' event
  */
  show(content_or_options, _options = {}) {
    let content = null
    let options = _options

    if (typeof content_or_options === 'string') {
      content = content_or_options
    } else {
      options = content_or_options
    }

    buildOptions(options)
    appendModalTemplate(options.id, options.template)

    const $modal = getModal(`#${options.id}`, options)

    applyOptionsTo($modal, options);

    if (content !== null) { // In case the ModalTemplate already has content and we don't want to overwrite it
      options.forceUpdate = true
      Dialog.update(content, options)
    }

    if (!modalIsVisible($modal)) {
      openModal($modal, options)

      $modal.one("hidden.bs.modal", () => { $modal.remove() });
    }

    return false
  },

  alert(message, customSettings) {
    const modalId = "alert-modal"
    const alertOptions = {
      id: modalId,
      zIndex: 10060,
      message: message,
      keyboard: false,
      centered: true,
      backdrop: 'static',
      template: alertDialogTemplate,
    }
    const options = $.extend({}, settings, alertOptions, customSettings)
    const content = alertDialogContent(options)

    Dialog.show(content, options)

    return false
  },

  confirmDialog(customSettings) {
    const modalId = "confirm-modal"
    const confirmOptions = {
      id: modalId,
      onHide() {
        $(`#${modalId}`).find(".btn-primary").off("click.submit")
      },
      onHidden() {
        if ($(`#${modalId}`).find(".btn-primary").data("ok")) {
          (options.ok || function() {})()
        } else {
          (options.cancel || function() {})()
        }
      },
      zIndex: 10050,
      animation: false,
      keyboard: false,
      centered: true,
      backdrop: 'static',
      template: alertDialogTemplate,
    }
    const options = $.extend({}, settings, confirmOptions, customSettings)
    const content = confirmDialogContent(options)

    Dialog.show(content, confirmOptions)

    $(`#${modalId}`).find(".btn-primary").one("click.submit", function () {
      $(this).data("ok", true)

      Common.Dialog.hide({ id: modalId })
    })

    return false
  },

  // OPTIONS - same as the show function
  lazyLoad(url, id, title, options = {}) {
    buildOptions(options)

    Dialog.show(lazyLoadContentTemplate(id, url, title), options)

    return false
  },

  // OPTIONS - same as the show function
  showRemote(url, options = {}) {
    buildOptions(options)
    const oldOnShown = options.onShown

    options.onShown = () => {
      $.ajax({
        url: url,
        success(data, _textStatus, _jqXHR) {
          Dialog.update(data, options)
        },
        error(jqXHR, _textStatus, _errorThrown) {
          Dialog.hide(options)

          Common.handleAjaxError(jqXHR, Common.Notification.error)
        }
      })

      if (oldOnShown) { oldOnShown() }
    }

    Dialog.show(loadingContentTemplate(options), options)

    return false
  },

  /*
    OPTIONS - same as the show function, plus
      forceUpdate == true # will update the modal's content regardless of it being visible or not
  */
  update(content, options = {}) {
    buildOptions(options)

    const $modal = getModal(`#${options.id}`, options)

    if (!$modal) { return false }

    const $modalContent = $modal.find(".modal-content")

    if (modalIsVisible($modal) || options.forceUpdate) {
      $modalContent.html(content)
    } else {
      // In case the modal has been dismissed by the user,
      //  we clear the contents of the modal for future use.
      //  unless the developer wants to force update it.
      $modalContent.html("")
    }

    return false
  },

  // OPTIONS - same as the show function
  hide(options = {}) {
    buildOptions(options)
    const $modal = getModal(`#${options.id}`, options)

    if ($modal.length) {
      $modal.data("modal").hide()
    }

    return false
  },

  open(selector, options = {}) {
    buildOptions(options);

    const $modal = getModal(selector, options);

    if ($modal.length) {
      applyOptionsTo($modal, options);

      openModal($modal, options);
    }

    return false;
  }
}

export default Dialog

/* ********************** PRIVATE ********************** */

function applyOptionsTo($modal, options) {
  const $modalDialog = $modal.find(".modal-dialog")

  if (options.animation === false) {
    $modal.removeClass("fade")
    delete options.animation
    $modal.one("hidden.bs.modal", () => $modal.addClass("fade"))
  }

  if (options.centered) {
    $modalDialog.addClass("modal-dialog-centered")
    delete options.centered
    $modal.one("hidden.bs.modal", () => $modalDialog.removeClass("modal-dialog-centered"))
  }

  if (options.scrollable) {
    $modalDialog.addClass("modal-dialog-scrollable")
    delete options.scrollable
    $modal.one("hidden.bs.modal", () => $modalDialog.removeClass("modal-dialog-scrollable"))
  }

  if (options.size) {
    $modalDialog.addClass({ 'large': 'modal-lg', 'small': 'modal-sm', }[options.size])
    delete options.size
    $modal.one("hidden.bs.modal", () => $modalDialog.removeClass("modal-lg modal-sm"))
  }
}

function appendModalTemplate(id, template) {
  if ($("#" + id).length) { return true }

  $("body").append(template({ id: id }))
}

function allPurposeModalTemplate({ id }) {
  return `<div id="${id}" class="modal fade" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
    </div>
  </div>
</div>`
}

function lazyLoadContentTemplate(id, url, title) {
  return `<div class="modal-header">
  <h4 class="modal-title">${title}</h4>

  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
</div>

<div id="${id}" data-src="${url}" data-controller="lazy-load">
  <div class="modal-body my-4">
    <div class="visible-on-busy" data-controller="spinner"></div>
    <div class="invisible-on-busy centered-overlay flex-column">
      <i class="fas fa-exclamation-triangle fa-2x text-danger"></i>
      <div>${settings.lazyLoadErrorMessage}</div>
    </div>
  </div>
</div>`
}

function loadingContentTemplate({ title, cancelButton }) {
  return `<div class="modal-header">
  <h4 class="modal-title">${title}</h4>

  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
</div>

<div class="modal-body my-4">
  <div data-controller="spinner"></div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-secondary me-2" data-bs-dismiss="modal">
    ${cancelButton}
  </button>
</div>`
}

function alertDialogTemplate({ id }) {
  return `<div id="${id}" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
    </div>
  </div>
</div>`
}

function alertDialogContent({ message, alertOkButton }) {
  return `<div class="modal-header border-bottom-0">
    <div class="d-flex py-2">
      <i class="fas fa-exclamation-triangle fa-3x text-warning me-3"></i>
      <h5 class="modal-title mt-1 mb-0">${message}</h5>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" data-bs-dismiss="modal">${alertOkButton}</button>
  </div>
</div>`
}

function confirmDialogContent({ confirmMessage, confirmCancelButton, confirmOkButton }) {
  return `<div class="modal-header border-bottom-0">
    <h5 class="modal-title">${confirmMessage}</h5>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">${confirmCancelButton}</button>
    <button type="button" class="btn btn-primary">${confirmOkButton}</button>
  </div>
</div>`
}

function openModal($modal, options) {
  let modal = $modal.data("modal");

  if (modal) return modal.show();

  modal = new Modal($modal.get(0), options);

  const zIndex = options.zIndex + ($(".modal").length * 2) - 1

  $modal.data("modal", modal)

  modal.show()

  $modal.css('z-index', zIndex)

  $($modal.data("modal")._backdrop).css('z-index', zIndex - 1)
}

function getModal(selector, options) {
  const $modal = $(selector)

  if (!$modal.length) { return false }

  attachEvents($modal, options)

  return $modal
}

function buildOptions(options) {
  Object.keys(settings).forEach((key) => {
    if (!options.hasOwnProperty(key)) {
      options[key] = settings[key]
    }
  })
}

function modalIsVisible($modal) {
  const modal = $modal.data('modal') || {}

  return !!modal._isShown
}

function attachEvents($modal, options) {
  if (options.onShow) {
    $modal.one("show.bs.modal", options.onShow)
    delete options.onShow
  }
  if (options.onShown) {
    $modal.one("shown.bs.modal", options.onShown)
    delete options.onShown
  }
  if (options.onHide) {
    $modal.one("hide.bs.modal", options.onHide)
    delete options.onHide
  }
  if (options.onHidden) {
    $modal.one("hidden.bs.modal", options.onHidden)
    delete options.onHidden
  }
}
