const defaultSettings = {
  reportErrors: false
}
const settings = { ...defaultSettings }

const ImageElement = {
  config(customSettings) {
    Object.assign(settings, customSettings);
  },

  handleOnLoad(event) {
    const element = event.target;

    Common.Spinner.stopClosestSpinner(element, { preventLoadAgain: true });
  },

  handleOnError(event) {
    const element = event.target;

    Common.Spinner.stopClosestSpinner(element, { preventLoadAgain: true });

    if (settings.reportErrors) {
      return reportError(element);
    }
  }
}

export default ImageElement

/* ********************** PRIVATE ********************** */

function reportError(element) {
  const url = element.src;

  if (!element.dataset.attachmentId) return;

  fetch(url, { method: 'HEAD' })
    .then(response => {
      if (response.status === 512) reportOriginLimitExceeded(element);
    });
}

function reportOriginLimitExceeded(element) {
  const attachmentId = element.dataset.attachmentId;

  $.ajax({
    type: 'PATCH',
    url: `/api/attachments/images/${attachmentId}/set_origin_limit_exceeded`,
    success(data, _textStatus, _jqXHR) {
      if (data.data.url) {
        element.src = data.data.url;
      }
    }
  });
}