import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const $element = $(this.element)
    const video = $element.find("video").get(0)

    if (video) {
      $element.on("mouseenter", () => {
        video.play()
      }).on("mouseleave", () => {
        video.pause()
      })
    }
  }
}
