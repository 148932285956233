// https://getbootstrap.com/docs/5.0/components/toasts/
import { Toast } from "bootstrap";

const defaultSettings = {
  toastPlacementId: "toastPlacement",
  animation: true,
  autohide: true,
  delay: 5000,
  placement: 'bottomRight',
};
const settings = { ...defaultSettings };

const Notification = {
  config(customSettings) {
    $.extend(true, settings, customSettings)
  },

  dark: appendAndShowToast.bind(null, "white", "dark"),
  info: appendAndShowToast.bind(null, "white", "info"),
  light: appendAndShowToast.bind(null, "black", "light"),
  alert: appendAndShowToast.bind(null, "white", "warning"),
  error: appendAndShowToast.bind(null, "white", "danger"),
  notice: appendAndShowToast.bind(null, "white", "success"),
  warning: appendAndShowToast.bind(null, "white", "warning"),
  success: appendAndShowToast.bind(null, "white", "success"),
  primary: appendAndShowToast.bind(null, "white", "primary"),
  secondary: appendAndShowToast.bind(null, "white", "secondary"),

  dismissAll() {
    document.querySelectorAll("[data-id='toast']").forEach(disposeToast);
  }
};

export default Notification;

/* ********************** PRIVATE ********************** */

function disposeToast(element) {
  const toast = new Toast(element);

  toast.dispose();
}

function showToast(element) {
  const toast = new Toast(element, settings);

  element.addEventListener('hidden.bs.toast', function () {
    element.remove();
  })

  toast.show();
}

function appendAndShowToast(textColor, bgColor, message, description = null) {
  const toast = appendToast(textColor, bgColor, message, description)

  showToast(toast);

  return false
}

function appendToast(textColor, bgColor, message, description = null) {
  const template = description ? bigToastTemplate : smallToastTemplate;

  const toastPlacement = buildOrGetToastPlacement()

  toastPlacement.insertAdjacentHTML("beforeend", template(textColor, bgColor, message, description));

  return toastPlacement.lastChild
}

function buildOrGetToastPlacement() {
  const toastPlacement = getToastPlacement();

  if (toastPlacement) { return toastPlacement }

  document
    .querySelector("body")
    .insertAdjacentHTML("beforeend", toastPlacementTemplate(settings.placement));

  return getToastPlacement()
}

function getToastPlacement() {
  return document.getElementById(settings.toastPlacementId);
}

function smallToastTemplate(textColor, bgColor, message) {
  return `<div data-id="toast" class="toast hide text-${textColor} bg-${bgColor} border-0" role="alert" aria-live="assertive" aria-atomic="true">
  <div class="d-flex">
    <div class="toast-body">
      ${message}
    </div>
    <button type="button" class="btn-close btn-close-${textColor} me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
  </div>
</div>`;
}

function bigToastTemplate(textColor, bgColor, message, description) {
  return `<div data-id="toast" class="toast hide text-${textColor} bg-${bgColor} border-0" role="alert" aria-live="assertive" aria-atomic="true">
  <div class="toast-header text-${textColor} bg-${bgColor}">
    <strong class="me-auto">${message}</strong>
    <button type="button" class="btn-close btn-close-${textColor}" data-bs-dismiss="toast" aria-label="Close"></button>
  </div>
  <div class="toast-body">
    ${description}
  </div>
</div>`;
}

function toastPlacementTemplate(placement) {
  const placementCSS = {
    topLeft: "top-0 start-0",
    topCenter: "top-0 start-50 translate-middle-x",
    topRight: "top-0 end-0",
    middleLeft: "top-50 start-0 translate-middle-y",
    middleCenter: "top-50 start-50 translate-middle",
    middleRight: "top-50 end-0 translate-middle-y",
    bottomLeft: "bottom-0 start-0",
    bottomCenter: "bottom-0 start-50 translate-middle-x",
    bottomRight: "bottom-0 end-0",
  };

  return `<div id="${settings.toastPlacementId}" class="toast-container position-fixed on-top-of-the-world ${placementCSS[placement]} p-3"></div>`;
}
