const CheckboxToggler = {
  load(selector) {
    const $label = $(selector)

    if ($label.data("checkboxToggler")) { return false }

    const $radioInput = $("#" + $label.attr("for"))

    if (!$radioInput.length) { return false }

    $radioInput.data("$label", $label)

    $label.on("click", toggleRadioInput)
    $radioInput.on("change", paintLabel)

    $label.data("checkboxToggler", true)
  },
}

export default CheckboxToggler

/* ********************** PRIVATE ********************** */

function toggleRadioInput(event) {
  const $label = $(this)
  const $radioInput = $("#" + $label.attr("for"))

  if ($radioInput.prop("checked")) {
    $radioInput.prop("checked", false)
  } else {
    $radioInput.prop("checked", true)
  }

  $('input[name="' + $radioInput.attr('name') + '"]').trigger("change")

  event.preventDefault()

  return false
}

function paintLabel() {
  const $radioInput = $(this)
  const $label = $radioInput.data("$label")

  const checkedClass = $label.data("checkedClass")
  const uncheckedClass = $label.data("uncheckedClass")

  if ($radioInput.prop("checked")) {
    $label.addClass(checkedClass).removeClass(uncheckedClass)
  } else {
    $label.addClass(uncheckedClass).removeClass(checkedClass)
  }
}
