// https://github.com/firebase/firebaseui-web

import firebase from "firebase/app"
import * as firebaseui from "firebaseui"

import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    try {
      init(this.element)
    } catch(error) {
      Turbolinks.visit("/users/sign_in?oauth_error=not_working")
    }
  }
}

/* ********************** PRIVATE ********************** */

let firebaseUI = null

function init(element) {
  const options = $(element).data()

  if (!firebaseUI) {
    firebase.initializeApp({
      apiKey: options.apiKey,
      projectId: options.projectId,
      authDomain: options.authDomain
    })

    firebaseUI = new firebaseui.auth.AuthUI(firebase.auth())
  }

  firebaseUI.start(element, firebaseuiSettings(element, options))
}

function firebaseuiSettings(element, options) {
  const settings = {
    callbacks: {
      // Called when the user has been successfully signed in.
      signInSuccessWithAuthResult: function(authResult, redirectUrl) {
        Common.Spinner.load(element)

        login(authResult)

        return false;
      }
    },

    signInFlow: "popup",
    signInOptions: [
      { provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID },
      { provider: 'apple.com' },
      { provider: firebase.auth.EmailAuthProvider.PROVIDER_ID },
    ]
  }

  if (options.tosUrl) { settings.tosUrl = options.tosUrl }
  if (options.privacyPolicyUrl) { settings.privacyPolicyUrl = options.privacyPolicyUrl }

  return settings
}

function login(authResult) {
  const user = authResult.user

  if (!user) { return false }

  firebase.auth().currentUser.getIdToken(true).then(function(idToken) {
    window.location.href = "/users/oauth/callback?token=" + idToken
  }).catch(function(error) {
    window.location.href = "/users/sign_in?oauth_error=" + error
  })
}
