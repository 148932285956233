function t(phrase, options = {}, config = {}) {
  config.endsWith ||= '}';
  config.startsWith ||= '${'

  let translation = phrase;

  for (let [key, value] of Object.entries(options)) {
    translation = translation.replaceAll(config.startsWith + key + config.endsWith, value);
  }

  return translation;
}

export default t
