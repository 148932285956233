const defaultSettings = {
  overrideDirectUploadEnd: true,
  overrideDirectUploadProgress: true,
  overrideDirectUploadInitialize: true,
  overrideDirectUploadErrorRecovery: true,
}
const settings = { ...defaultSettings }

const DirectUpload = {
  config(customSettings) {
    $.extend(true, settings, customSettings)

    if (settings.overrideDirectUploadInitialize) {
      addDirectUploadInitialize()
      settings.overrideDirectUploadInitialize = false
    }

    if (settings.overrideDirectUploadProgress) {
      addDirectUploadProgress()
      settings.overrideDirectUploadProgress = false
    }

    if (settings.overrideDirectUploadErrorRecovery) {
      addDirectUploadErrorRecovery()
      settings.overrideDirectUploadErrorRecovery = false
    }

    if (settings.overrideDirectUploadEnd) {
      addDirectUploadEnd()
      settings.overrideDirectUploadEnd = false
    }
  },
}

export default DirectUpload

/* ********************** PRIVATE ********************** */

function addDirectUploadInitialize() {
  document.addEventListener("direct-upload:initialize", function(event) {
    const { target } = event
    const $target = $(target)

    if ($target.length) {
      getProgressContainter($target).removeClass("d-none")
    }
  })
}

function addDirectUploadProgress() {
  document.addEventListener("direct-upload:progress", function(event) {
    const { target, detail } = event
    const { progress } = detail
    const $target = $(target)

    if ($target.length) {
      getProgressContainter($target).find(".progress-bar").width(progress)
    }
  })
}

function addDirectUploadErrorRecovery() {
  document.addEventListener("direct-upload:error", function(event) {
    event.preventDefault()

    const { error } = event.detail

    Common.Notification.error(error)
  })
}

function addDirectUploadEnd() {
  document.addEventListener("direct-upload:end", function(event) {
    const { target } = event
    const $target = $(target)

    if ($target.length) {
      getProgressContainter($target).addClass("d-none")
    }
  })
}

function getProgressContainter($target) {
  const progressContainerSelector = $target.data("progressContainer")
  const $progressContainer = $(progressContainerSelector)

  if ($progressContainer.length) {
    return $progressContainer
  }

  return $target.parent().find(".progress")
}