import { Controller } from "stimulus"

export default class extends Controller {
  static values = { id: String, close: Boolean }

  connect() {
    if (this.closeValue) {
      Common.Dialog.hide({ id: this.idValue })
    }
  }
}
