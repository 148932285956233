function loadCssFile(id, path) {
  if (document.getElementById(id)) { return false }

  const head = document.getElementsByTagName("head")[0]
  const link = document.createElement("link")

  link.id = id
  link.rel = "stylesheet"
  link.type = "text/css"
  link.href = path
  link.media = "all"
  head.appendChild(link)
}

export default loadCssFile
