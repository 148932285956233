import Rails from "@rails/ujs"

const defaultSettings = {
  okButton: "Ok",
  cancelButton: "Cancel",
  overrideJquerySubmit: true,
  overrideRailsConfirm: true,
  overrideRemoteErrorRecovery: true,
}
const settings = { ...defaultSettings }

const Ujs = {
  config(customSettings) {
    $.extend(true, settings, customSettings)

    if (settings.overrideJquerySubmit) {
      addRailsUjsTojQuery()
      settings.overrideJquerySubmit = false
    }

    if (settings.overrideRailsConfirm) {
      Rails.confirm = confirmDialog
      settings.overrideRailsConfirm = false
    }

    if (settings.overrideRemoteErrorRecovery) {
      addRemoteErrorRecovery()
      settings.overrideRemoteErrorRecovery = false
    }
  },
}

export default Ujs

/* ********************** PRIVATE ********************** */

function addRailsUjsTojQuery() {
  var oldClick = jQuery.fn.click
  var oldSubmit = jQuery.fn.submit

  jQuery.fn.submit = function() {
    var $this = this

    if ($this.data("remote") === true) {
      return $this.each(function() { Rails.fire(this, 'submit') })
    } else {
      return oldSubmit.apply(this, arguments)
    }
  }

  jQuery.fn.click = function() {
    if (this.data("remote") === true) {
      return this.each(function() { Rails.fire(this, 'click') })
    } else {
      return oldClick.apply(this, arguments)
    }
  }
}

function confirmDialog(message, element) {
  const $element = $(element)

  if ($element.data("confirmed")) {
    $element.data("confirmed", false)
    return true
  }

  Common.Dialog.confirmDialog({
    confirmOkButton: settings.okButton,
    confirmCancelButton: settings.cancelButton,
    confirmMessage: message,
    ok: function () {
      $element.data("confirmed", true)

      Rails.fire(element, element.tagName == "BUTTON" ? "submit" : "click")
    }
  })

  return false
}

function addRemoteErrorRecovery() {
  document.addEventListener("ajax:error", function(event) {
    if (((event.srcElement || {}).dataset || {}).remote) {
      const [_data, _status, xhr] = event.detail
      const formId = event.target.id

      if (event.target.nodeName === "FORM" && formId) {
        $(`[form="${formId}"]`).each(function () {
          Common.LoadingButton.stop(this)
        })
      }

      Common.handleAjaxError(xhr, Common.Notification.error)
    }
  })
}
