import t from "./utils/t"

const defaultSettings = {
  maxMbSize: 10,
  fileIconUrl: "",
  throttleDelay: 200,
  sizeValidation: "Size bigger than ${maxSize}MB",
  limitValidation: "No more than ${limit} file(s), please.",
  ignoreDuplicates: true,
  contentTypeValidation: "Only ${contentType} type is allowed",
}
const settings = { ...defaultSettings }

const FileInput = {
  config(customSettings) {
    $.extend(true, settings, customSettings)
  },

  previewFileFromInput(input, targetSelector, template, removeButton) {
    const options = $.extend({}, settings, $(input).data())

    $(targetSelector).find(".attachment-preview").remove()

    if (!input.files) { return }

    const file = input.files[0]
    const previewParams = { ...options, removeButton: removeButton, errorMessage: validateFile(file, options) }

    const $preview = appendPreviewTemplate(targetSelector, template, previewParams)
    addFileSrcToPreview(file, $preview, options)
  },

  breakIntoSeparateFiles(input, targetSelector, template, callback = () => {}) {
    const $input = $(input)
    const options = $.extend({}, settings, $input.data())

    if (!input.files) { return }

    const maxLimit = Number(options.limit || 0) || 0
    let currentLimit = input.files.length

    if (maxLimit > 0) {
      currentLimit = maxLimit - $(targetSelector).find(".attachment-preview").length
    }

    if (currentLimit > input.files.length) {
      currentLimit = input.files.length
    }

    for(let i = 0; i < currentLimit; i++) {
      const file = input.files[i]

      if (options.ignoreDuplicates && alreadyAdded(file, targetSelector)) { continue }

      const previewParams = { ...options, fileField: true, removeButton: true, errorMessage: validateFile(file, options) }

      setTimeout(function () {
        const $preview = appendPreviewTemplate(targetSelector, template, previewParams)

        addFileToPreview(file, $preview)
        addFileSrcToPreview(file, $preview, options)

        callback(file, $preview, previewParams)
      }, i*options.throttleDelay)
    }

    if (input.files.length > currentLimit) {
      Common.Notification.error(t(settings.limitValidation, { limit: maxLimit }))
    }

    $input.val([])
  },

  appendPreviewTemplate: appendPreviewTemplate
}

export default FileInput

/* ********************** PRIVATE ********************** */

function appendPreviewTemplate(targetSelector, template, previewParams = {}) {
  const $preview = $(t(template, previewParams)).appendTo(targetSelector)

  if (!previewParams.removeButton) { $preview.find("[data-id='remove-button']").remove() }
  if (!previewParams.downloadUrl) { $preview.find("[data-id='download-link']").remove() }
  if (!previewParams.signedId) { $preview.find("[data-id='hidden-input']").remove() }
  if (!previewParams.fileField) { $preview.find("[data-id='file-input']").remove() }
  if (!previewParams.errorMessage) { $preview.find("[data-id='error-message']").remove() }

  return $preview
}

function addFileToPreview(file, $preview) {
  const input = $preview.find("input[type='file']")[0]

  if (!input) { return }

  const dataTransfer = new DataTransfer()
  dataTransfer.items.add(file)
  input.files = dataTransfer.files
}

function addFileSrcToPreview(file, $preview, options) {
  const mediaElement = $preview.find("[data-id='media-element']")[0]

  if (!mediaElement) { return }

  if (file.type.match(/image/)) {
    const reader = new FileReader()
    reader.onload = function (e) { mediaElement.src = e.target.result }
    reader.readAsDataURL(file)
  } else if (file.type.match(/video/)) {
    if (mediaElement) {
      mediaElement.src = URL.createObjectURL(file)
      mediaElement.parentElement.load()
    }
  } else {
    mediaElement.src = options.fileIconUrl
  }
}

function validateFile(file, options) {
  if (options.regex && !file.type.match(new RegExp(options.regex))) {
    return t(options.contentTypeValidation, { contentType: options.regex })
  }

  if (options.maxMbSize && file.size > (options.maxMbSize*1000*1000)) {
    return t(options.sizeValidation, { maxSize: options.maxMbSize })
  }

  return null
}

function alreadyAdded(file, targetSelector) {
  const $inputs = $(targetSelector).find(".attachment-preview")
  let foundDuplicate = false

  $inputs.each(function() {
    const $this = $(this)
    const $input = $this.find("input[type='file']")
    const existingFile = $input.get(0).files[0]

    if (existingFile.name === file.name && existingFile.size === file.size) {
      $this.addClass("highlight")
      $this.on("animationend", () => $this.removeClass("highlight"))

      return (foundDuplicate = true)
    }
  })

  return foundDuplicate
}
