const Validations = {
  enforceMaxValue(selector) {
    const element = Common.getElement(selector);
    const data = Common.getDataset(selector);
    const oldValue = element.dataset['oldValue'];

    element.value = element.value.replace(/\D/g, '');

    if (Number(element.value) > element.max) {
      element.value = oldValue || element.max;
    }

    element.dataset['oldValue'] = element.value;
  },

  validatePresence(selector) {
    const element = Common.getElement(selector);
    const value = element.value;
    const valid = (value || '') !== ''

    if (valid) {
      Common.FormErrors.removeError(selector)

      return true
    } else {
      Common.FormErrors.showError(selector, 'blank')

      return false
    }
  },

  validateDuration(selector) {
    const element = Common.getElement(selector);
    const parent = element.parentElement;
    const allInputs = parent.querySelectorAll('input');
    let valid = false;

    allInputs.forEach(input => {
      if (Number(input.value) > 0) valid = true;
    });

    if (valid) {
      Common.FormErrors.removeError(parent)

      return true
    } else {
      Common.FormErrors.showError(parent, 'blank')

      return false
    }
  },

  validateURL(selector) {
    const element = Common.getElement(selector);
    const value = element.value;
    const urlRegex = '^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
    const url = new RegExp(urlRegex, 'i');
    const valid = value.length < 2083 && url.test(value);

    if (valid) {
      Common.FormErrors.removeError(selector);

      return true;
    } else {
      Common.FormErrors.showError(selector, 'invalid');

      return false;
    }
  }

  // validateForm(selector) {
  //   const $element = $(selector)
  //   const $form = $element.is('form') ? $element : $element.closest('form')

  //   $form.find('input').each(function () {
  //     $(this)
  //       .triggerStimulus('input')
  //       .triggerStimulus('change')
  //   })

  //   if ($(`.${settings.messageInvalidClass}`).length) {
  //     event.preventDefault()
  //     return false
  //   } else {
  //     return true
  //   }
  // }
};

export default Validations;

