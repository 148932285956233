// https://getbootstrap.com/docs/5.3/components/offcanvas/#how-it-works
import { Offcanvas } from "bootstrap"

const defaultSettings = {
  id: "all-purpose-slider",
  lazyLoadErrorMessage: "Failed to load content.",
  template: allPurposeTemplate,
  keyboard: true,
  backdrop: true,
  placement: 'end'
}
const settings = { ...defaultSettings }

const Slider = {
  config(customSettings) {
    $.extend(true, settings, customSettings);
  },

  /*
    OPTIONS
      id != null # will dictate which html element to use for the slider
      template != undefined # will overwrite the Slider's template
      onShow != undefined # will add that callback to 'show.bs.offcanvas' event
      onShown != undefined # will add that callback to 'shown.bs.offcanvas' event
      onHide != undefined # will add that callback to 'hide.bs.offcanvas' event
      onHidden != undefined # will add that callback to 'hidden.bs.offcanvas' event
  */
  show(content_or_options, _options = {}) {
    let content = null
    let options = _options

    if (typeof content_or_options === 'string') {
      content = content_or_options
    } else {
      options = content_or_options
    }

    buildOptions(options)
    appendTemplate(options.template, options)

    const $slider = getSlider(options)

    if (content !== null) {
      options.forceUpdate = true
      Slider.update(content, options)
    }

    if (!isVisible($slider)) {
      openSlider($slider, options)

      $slider.one("hidden.bs.offcanvas", () => { $slider.remove() })
    }

    return false
  },

  // OPTIONS - same as the show function
  lazyLoad(url, id, title, options = {}) {
    buildOptions(options)

    Slider.show(lazyLoadContentTemplate(id, url, title), options)

    return false
  },

  // OPTIONS - same as the show function
  showRemote(url, options = {}) {
    buildOptions(options)
    const oldOnShown = options.onShown

    options.onShown = () => {
      $.ajax({
        url: url,
        success(data, _textStatus, _jqXHR) {
          Slider.update(data, options)
        },
        error(jqXHR, _textStatus, _errorThrown) {
          Slider.hide(options)

          Common.handleAjaxError(jqXHR, Common.Notification.error)
        }
      })

      if (oldOnShown) { oldOnShown() }
    }

    Slider.show(loadingContentTemplate(options), options)

    return false
  },

  /*
    OPTIONS - same as the show function, plus
      forceUpdate == true # will update the slider's content regardless of it being visible or not
  */
  update(content, options = {}) {
    buildOptions(options)

    const $slider = getSlider(options)

    if (!$slider) { return false }

    const $content = $slider.find(".offcanvas-content")

    if (isVisible($slider) || options.forceUpdate) {
      $content.html(content)
    } else {
      // In case the slider has been dismissed by the user,
      //  we clear the contents of the slider for future use.
      //  unless the developer wants to force update it.
      $content.html("")
    }

    return false
  },

  // OPTIONS - same as the show function
  hide(options = {}) {
    buildOptions(options)
    const $slider = getSlider(options)

    if ($slider.length) {
      $slider.data("slider").hide()
    }

    return false
  }
}

export default Slider

/* ********************** PRIVATE ********************** */

function appendTemplate(templateFunc, data) {
  if ($("#" + data.id).length) { return true }

  $("body").append(templateFunc(data))
}

function allPurposeTemplate({ id, placement }) {
  return `<div class="offcanvas offcanvas-${placement}" tabindex="-1" id="${id}">
  <div class="offcanvas-content"></div>
</div>`
}

function lazyLoadContentTemplate(id, url, title) {
  return `<div class="offcanvas-header">
  <h5 class="offcanvas-title">${title}</h5>
  <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
</div>

<div id="${id}" data-src="${url}" data-controller="lazy-load" class="offcanvas-body">
  <div class="my-4">
    <div class="visible-on-busy" data-controller="spinner"></div>
    <div class="invisible-on-busy centered-overlay flex-column">
      <i class="fas fa-exclamation-triangle fa-2x text-danger"></i>
      <div>${settings.lazyLoadErrorMessage}</div>
    </div>
  </div>
</div>`
}

function loadingContentTemplate({ title, placement }) {
  const closeButton = `<div data-bs-theme="dark">
  <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
</div>
`;
  let leftCloseButton = '';
  let rightCloseButton = '';

  if (placement === 'start') {
    leftCloseButton = closeButton;
  } else {
    rightCloseButton = closeButton;
  }

  return `<div class="offcanvas-header">
  ${leftCloseButton}<h5 class="offcanvas-title">${title}</h5>${rightCloseButton}
</div>

<div class="offcanvas-body">
  <div class="my-4">
    <div data-controller="spinner"></div>
  </div>
</div>`
}

function openSlider($slider, options) {
  const slider = new Offcanvas($slider.get(0), options)
  const zIndex = options.zIndex + ($(".slider").length * 2) - 1

  $slider.data("slider", slider)

  setTimeout(() => {
    slider.show();
  }, 50);
}

function getSlider(options) {
  const $slider = $("#" + options.id)

  if (!$slider.length) { return false }

  attachEvents($slider, options)

  return $slider
}

function buildOptions(options) {
  Object.keys(settings).forEach((key) => {
    if (!options.hasOwnProperty(key)) {
      options[key] = settings[key]
    }
  })
}

function isVisible($slider) {
  const slider = $slider.data('slider') || {}

  return !!slider._isShown
}

function attachEvents($slider, options) {
  if (options.onShow) {
    $slider.one("show.bs.offcanvas", options.onShow);

    if (isVisible($slider)) options.onShow();

    delete options.onShow;
  }

  if (options.onShown) {
    $slider.one("shown.bs.offcanvas", options.onShown);

    if (isVisible($slider)) options.onShown();

    delete options.onShown;
  }

  if (options.onHide) {
    $slider.one("hide.bs.offcanvas", options.onHide);

    delete options.onHide;
  }

  if (options.onHidden) {
    $slider.one("hidden.bs.offcanvas", options.onHidden);

    delete options.onHidden;
  }
}
