const onEvents = {
  clickin: onClickIn,
  clickout: onClickOut,
  click: onClick,

  focusin: onFocusIn,
  focusout: onFocusOut,
  focus: onFocus,

  hoverin: onHoverIn,
  hoverout: onHoverOut,
  hover: onHover,
}

const ToggleDataOn = {
  load(selector) {
    const $element = $(selector)
    const onEvent = $element.data("onEvent") || "click"
    const dataAttr = $element.data("dataAttr") || "active"
    const toggleMode = $element.data("toggleMode") || "add"
    const $target = getTarget($element.data("target"), $element)

    if ($element.data("toggle-data-on")) { return false }

    onEvent.split(" ").forEach(function (eventName) {
      onEvents[eventName]($element, $target, dataAttr, toggleMode)
    })

    $element.data("toggle-data-on", true)
  }
}

export default ToggleDataOn

/* ********************** PRIVATE ********************** */

function getTarget(targetSelector, $element) {
  if (!targetSelector) { return $element }

  if (targetSelector === "parent") { return $element.parent() }

  return $(targetSelector)
}

function onClickIn($element, $target, dataAttr, toggleMode) {
  $element.on("click", function() { onEvent($target, dataAttr, toggleMode) })
}
function onClickOut($element, $target, dataAttr, toggleMode) {
  // $(window).click(function() {
  //   //Hide the menus if visible
  // });
}
function onClick($element, $target, dataAttr, toggleMode) {
  onClickIn($element, $target, dataAttr, toggleMode)
  onClickOut($element, $target, dataAttr, toggleMode)
}

function onFocusIn($element, $target, dataAttr, toggleMode) {
  $element.on("focusin", function() { onEvent($target, dataAttr, toggleMode) })
}
function onFocusOut($element, $target, dataAttr, toggleMode) {
  $element.on("focusout", function() { offEvent($target, dataAttr, toggleMode) })
}
function onFocus($element, $target, dataAttr, toggleMode) {
  onFocusIn($element, $target, dataAttr, toggleMode)
  onFocusOut($element, $target, dataAttr, toggleMode)
}

function onHoverIn($element, $target, dataAttr, toggleMode) {
  $element.on("mouseenter", function() { onEvent($target, dataAttr, toggleMode) })
}
function onHoverOut($element, $target, dataAttr, toggleMode) {
  $element.on("mouseleave", function() { offEvent($target, dataAttr, toggleMode) })
}
function onHover($element, $target, dataAttr, toggleMode) {
  onHoverIn($element, $target, dataAttr, toggleMode)
  onHoverOut($element, $target, dataAttr, toggleMode)
}

function onEvent($target, dataAttr, toggleMode) {
  if (toggleMode === 'add') {
    $target.attr(`data-${dataAttr}`, true)
  } else {
    $target.removeAttr(`data-${dataAttr}`)
  }
}

function offEvent($target, dataAttr, toggleMode) {
  if (toggleMode === 'add') {
    $target.removeAttr(`data-${dataAttr}`)
  } else {
    $target.attr(`data-${dataAttr}`, true)
  }
}
